import React, { Ref, useEffect, useState, useImperativeHandle } from 'react'
import { customerApi } from "@/apis"
import { CustomerSimpleInfo } from "@/types/customer"
import { setCustomerId } from '@/utils/local'
import { ResToast } from '@/utils'
import defaultAvatar from '@/assets/defaultAvatar.jpg'
import './index.scss'

type IR = {
    getUserInfo: () => CustomerSimpleInfo
}

function UserInfo(props: any, ref: Ref<IR>) {
    const [userInfo, setUserInfo] = useState({} as CustomerSimpleInfo)

    useImperativeHandle(ref, () => ({
        // 需要把函数组件对外暴露的的事件写到这里
        getUserInfo: () => userInfo
    }))
    useEffect(() => {
        const fetchUserData = async () => {
            window?.Loading?.show()
            const userInfoRes = await customerApi.userInfo()
            window?.Loading?.hide()
            if(!userInfoRes?.data?.success) {
                ResToast(userInfoRes?.data)
                return
            }
            setCustomerId(userInfoRes?.data?.obj?.id)
            setUserInfo(userInfoRes?.data?.obj)
        }
        fetchUserData()
    }, [])
    const { email, phone } = userInfo || {}
    return (
        <div className="user-info">
            <div className="head">
                <img className="head-img" alt="head" src={defaultAvatar} />
            </div>
            <div className="info">
                {email && <div className="email">{email}</div>}
                <div className="phone">{phone}</div>
            </div>
        </div>
    )
}
export default React.forwardRef(UserInfo)