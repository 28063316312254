import Config from "./envs"

const envEum = {
  prod: 'prod',
  dev: 'dev',
  beta:  'beta',
}

const ENVIRONMENT = process.env.REACT_APP_ENV ?? "dev"

const websocket: any = {
  host: 'ws://ws.kikitrade.com:8080',
  appCode: 'c022929d0e1f4b0d8eace9cb9934bf0d',
  stage: 'RELEASE'
}

export const verifyInputSceneEum = {
  login: 'nc_login_h5',
  other: 'nc_other_h5'
}

export const businessTypeEum = {
  login: 14,
  vibraPay: 15
}

export const orderStatusEum: any = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  PAID: 'PAID',
  CANCELED: 'CANCELED'
}

export const readonlyToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYW5vbnltb3VzIn0.bdFfo5zO73GK4DMqJE8Kxro3KHyEQqZ5aw7puIiqS0U'
const env = envEum[ENVIRONMENT]

const config = (Config as any)[env]

const host = config.HOST
const socialHost = config.SOCIAL_HOST
const urlCdn = config.URL_CDN
const shareUrl: string = config.SHARE_URL
const isDev = process.env.NODE_ENV === "development"

export {
  isDev,
  env,
  host,
  socialHost,
  urlCdn,
  websocket,
  shareUrl
}