const MsgKey = {
  CUSTOMER_H5_UNREGISTRATION: "customer.login.password.error.mobile",
  CUSTOMER_REGISTRATION_SUCCESS: "customer.registration.success",
  CUSTOMER_VERIFY_CODE_SENT: "customer.verify.code.sent", // 0105
  CUSTOMER_LOGIN_SUCCESS: "customer.login.success", // 0110
  CUSTOMER_QUERY_SUCCESS: "customer.query.success", // 0117
  CUSTOMER_VERIFY_TYPE_INVALID: "customer.verify.type.invalid", // 01
  CUSTOMER_EXIST_NONE: "customer.exist.none", // 1020
  CUSTOMER_LOCKED: "customer.locked", // 1022
  CUSTOMER_MAIL_CODE_INCORRECT: "customer.email.code.incorrect",
  CUSTOMER_PHONE_CODE_INCORRECT: "customer.phone.code.incorrect", // 1009
  CUSTOMER_PHONE_CODE_OFTEN: "customer.phone.code.often", // 1009
  CUSTOMER_PHONE_CODE_LIMIT: "customer.phone.code.limit", // 1013
  CUSTOMER_VERIFY_CODE_SENT_OFTEN: "customer.verify.code.sent.often",
  CUSTOMER_EMAIL_FORMAT_INCORRECT: "customer.email.required",
  CUSTOMER_PHONE_FORMAT_INCORRECT: "customer.phone.format.incorrect",
  P2P_MERCHANT_APPLY_INSUFFICIENT: "p2p.merchant.apply.insufficient", // 7204
  P2P_NUMBER_OF_ADS_GREATER_THAN_MAX: "p2p.number.of.ads.greater.than.max", // 7210
  P2P_ORDERMAX_CANNOT_LESS_THAN_ORDERMIN: "p2p.ordermax.cannot.less.than.ordermin", // 7211
  P2P_AMOUNT_CANNOT_LESS_THAN_ORDERMAX: "p2p.amount.cannot.less.than.ordermax", // 7212
  P2P_ORDER_LIMIT_CHCEK_FAIL: "p2p.order.limit.chcek.fail", // 7220
  P2P_MERCHANT_ADS_POST_INSUFFICIENT: "p2p.merchant.ads.post.insufficient", // 7214
  P2P_ORDER_CANCEL_MORE_THAN_THREE_TIMES: "p2p.cancel.more.than.three.times", // 7231
  P2P_MERCHANT_ADS_OFFLINE: "p2p.merchant.ads.offline", // 7236
  P2P_ORDER_NEED_TO_RESUBMIT: "p2p.order.need.to.resubmit", // 7229
  ORDER_IS_BOUND: "order.is.bound",
  ORDER_CANCELED: "order.canceled",
  CUSTOMER_LOGIN_REQUIRED: 'customer.login.required'
}
export default MsgKey