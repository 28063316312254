export const getToken = () => {
    return localStorage.getItem('token')
}

export const setToken = (value: string) => {
  return localStorage.setItem('token', value)
}

export const removeToken = async () => {
  await localStorage.removeItem("token")
}

export const getCustomerId = () => {
  return localStorage.getItem('customerId')
}

export const setCustomerId = (value: string) => {
  return localStorage.setItem('customerId', value)
}

export const removeCustomerId = () => {
  return localStorage.removeItem('customerId')
}