const CodeRes: any = {
    'try.again.later': 'Please try again later', // 请稍后再试
    'order.init.process.fail': 'Order failed, please order again.', // 创单失败
    'order.confirm.process.fail': "Order confirmation failure", // 订单确认失败
    'order.cancel.process.fail': "Order cancellation failure", // 订单取消失败
    'transfer.process.fail': "Transfer failed", // 转账失败
    'invalid.keys': "Invalid key value", // 无效key
    'data.not.found': "No data found", // 未查到数据
    'accounting.error': "Account processing exceptions", // 账务处理失败
    'system.request.error': "Request exception", // 请求异常
    'system.error': "System error", // 系统错误
    'param.illegal': "Invalid parameter", // 参数不合法
    'user.not.exist': "The user does not exist", // 用户不存在
    'order.already.confirm': "Order confirmed", // 订单已确认
    'order.canceled': "Order cancelled", // 订单已取消
    'order.paid': "Order completed", // 订单已完成
    'order.is.bound': "No payment order yet, please create your order first", // 订单已绑定
    'merchant.data.not.found': "The merchant not found", // 商户未找到
    'customer.phone.code.limit': 'SMS sent beyond the maximum limit of the day', // 验证码超过验证次数限制
    'customer.phone.code.often': 'Verification code sent too often. Please try later', // 发送手机验证码太频繁
    'business.process.fail': 'The business process fail , please contact the developers', // 业务流程失败
    'currency.not.supported': 'currency not supported', // 币种不支持
}
export default CodeRes