import { useEffect, useState, useRef } from 'react'
import { useLocation } from "react-router-dom"
import moment from 'moment'
import ClipboardJS from 'clipboard'
import copyLogo from '@/assets/copyLogo.png'
import Header from '@/components/Header'
import BuzzLogo from '@/components/BuzzLogo'
import { orderStatusEum } from '@/config/index'
import { ResToast, formatFixNumString } from '@/utils'
import { Toast } from 'antd-mobile'
import { payApi } from '@/apis'
import successfullyLogo from '@/assets/successfullyLogo.png'
import failureLogo from '@/assets/failureLogo.png'
import './index.scss'

const initOrderInfo = {
    orderAmount: 0,
    paymentId: '',
    currency: '',
    status: '',
    merchantName: '',
    merchantOrderNo: '',
    payTime: '',
    paymentType: '' as ('BALANCE_PAYMENT' | 'ONE_TIME_DEBIT'),
    paymentOrderNo: '',
    redirectUrl: '',
    customizations: {
        logo: '',
        title: ''
    },
    referenceOrderId: ''
}
let timer: any

const payResultInfoEum = {
    success: {
        title: 'Payment Succeed',
        logo: successfullyLogo
    },
    fail: {
        title: 'Payment Failed',
        logo: failureLogo
    }
}

const paymentTypeTextEum = {
    BALANCE_PAYMENT: 'Wallet Balance',
    ONE_TIME_DEBIT: 'Deposit and Payment'
}

export default function PayResult() {
    const { state } = useLocation()
    const { token } = state as { token: string } || {}

    let payResultInfo = {
        title: '',
        logo: ''
    }

    const intervalRef = useRef<any>(null)
    const [seconds, setSeconds] = useState(3)  //秒
    const [orderInfo, setOrderInfo] = useState(initOrderInfo)

    const [copyId, setCopyId] = useState('')

    const formatOrderAmount = `${formatFixNumString(orderInfo.orderAmount || 0)} ${orderInfo.currency || ''}`

    const { status = '', merchantName = '', payTime = '', paymentType, paymentId, customizations, referenceOrderId } = orderInfo

    if(status === orderStatusEum.PAID) {
        payResultInfo = payResultInfoEum.success
    }else if(orderStatusEum[status]){
        payResultInfo = payResultInfoEum.fail
    }
    const formatPayTime = (payTime && moment(payTime).format("YYYY-MM-DD HH:mm:ss")) || ''


    const payResultData = [
        {
            desc: 'Pay Status',
            value: status || ''
        },
        {
            desc: 'Pay Crypto Amount',
            value: formatOrderAmount
        },
        {
            desc: 'Merchant Name',
            value: merchantName
        },
        {
            desc: 'Payment Time',
            value: formatPayTime
        },
        {
            desc: 'Payment Type',
            value: paymentTypeTextEum[paymentType] || ''
        },
        {
            desc: 'Payment Order No.',
            value: paymentId,
            copy: true
        },
        {
            desc: 'Merchant Order No.',
            value: referenceOrderId,
            copy: true
        }
    ]
    const vaildPayResultData = payResultData.filter((item) => !!item.value)

    const handleGoBack = () => {
        orderInfo.redirectUrl && window.location.replace(orderInfo.redirectUrl)
    }
    intervalRef.current = () => {
        if(!seconds) {
            clearInterval(timer)
            handleGoBack()
            return
        }
        setSeconds(seconds - 1)
    }

    useEffect(() => {
        const fetchData = async () => {
            window?.Loading?.show()
            const paymentsDetailRes = await payApi.getPaymentsDetail(token) || {}
            window?.Loading?.hide()
            if(!paymentsDetailRes?.data?.success) {
                ResToast(paymentsDetailRes?.data)
                return
            }
            const { orderAmount, status, merchant, merchantOrderNo, payTime, paymentType, paymentOrderNo, redirectUrl, customizations, paymentId, referenceOrderId } = paymentsDetailRes?.data?.obj
            setOrderInfo({
                orderAmount: orderAmount?.value,
                currency: orderAmount?.currency,
                status,
                merchantName: merchant?.merchantName,
                redirectUrl,
                merchantOrderNo,
                payTime,
                paymentType,
                paymentOrderNo,
                customizations,
                paymentId,
                referenceOrderId
            })
            timer = setInterval(() => {
                intervalRef.current()
            }, 1000)
        }
        fetchData()
        return () => clearInterval(timer)
    }, [token])

    useEffect(() => {
        const clipboard = new ClipboardJS('.user-order-id-copy', {
            text: function () {
                return copyId
            }
        })
        clipboard.on('success', () => {
            Toast.show('Copy Success')
        })
        clipboard.on('error', () => {
            Toast.show('Copy Failed')
        })
    }, [copyId])

    return (
        <div className="pay-result">
            <Header isBack={false} />
            <div className="pay-result-content">
                <BuzzLogo logo={customizations?.logo} />
                <div className="success-tips">
                    <img className="success-logo" alt="" src={payResultInfo.logo} />
                    <div className="desc">{payResultInfo.title}</div>
                </div>
                <div className="jump-tips">
                    <div className="tips">The page will return to BetBuzz in {seconds} seconds.</div>
                    <div className="tips">Please take a screenshot of this page and save it.</div>
                </div>
                <div className="pay-result-detail">
                    {
                        vaildPayResultData.map((payResult, index) => {
                            const { desc, value, copy } = payResult
                            return (
                                <div className="result-item" key={index}>
                                    <div className="desc">{desc}</div>
                                    <div
                                        onClick={() => {
                                            if(!copy) return
                                            setCopyId(value || '')
                                        }}
                                        className={`value ${copy ? 'user-order-id-copy' : ''}`}
                                    >
                                        {value}{copy && value && <div className='copy-img'><img src={copyLogo} alt="copy" /></div>}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
               <div className="go-back" onClick={handleGoBack}>Back To {merchantName || ''}</div>
            </div>
        </div>
    )
}