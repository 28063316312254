import { useState, useEffect } from 'react'
import { LogEvent, formatFixNumString } from '@/utils'
import moment from 'moment'
import ClipboardJS from 'clipboard'
import copyLogo from '@/assets/copyLogo.png'
import { Button, Radio, Toast } from 'antd-mobile'
import { vibraPayAgreementUrl } from '@/constants'
import './index.scss'

type IPayCardProps = {
    showPayMethod?: boolean
    onOrderPay?: (payMethod: string) => void
    avaAmount?: string
    systemError?: boolean
    orderInfo?: {
        orderAmount: number,
        currency: string,
        orderDescription: string,
        merchantName: string,
        paymentId?: string,
        referenceOrderId: string
        requestTime: string
        buyerNickName: string
    }
}
const payMethodEum = {
    walletPay: 'BALANCE_PAYMENT',
    depositPay: 'ONE_TIME_DEBIT'
}

export default function PayCard(props: IPayCardProps) {
    const { showPayMethod = false, onOrderPay, avaAmount = '', orderInfo, systemError } = props
    const defaultPayMethod = showPayMethod ? payMethodEum.walletPay : ''
    const [payMethod, setPayMethod] = useState(defaultPayMethod)
    const { merchantName = '', orderDescription = '', paymentId = '', currency, orderAmount = 0, referenceOrderId, requestTime, buyerNickName } = orderInfo || {}
    const [isDisabled, setIsDisabled] = useState(true)
    const disabled = systemError || isDisabled

    const [agreeProtocol, setAgreeProtocol] = useState(false)

    const [copyId, setCopyId] = useState('')

    const formatOrderAmount = `${formatFixNumString(orderAmount)} ${currency || ''}`
    const isDepositPay = payMethod === payMethodEum.depositPay

    const formatRequestTime = (requestTime && moment(requestTime).format("YYYY-MM-DD HH:mm:ss")) || ''
    const payDetailList = [
        {
            desc: 'Merchant Order No.',
            value: referenceOrderId,
            copy: true
        },
        {
            desc: "Payment Order No.",
            value: paymentId,
            copy: true
        },
        {
            desc: "Order Description",
            value: orderDescription
        },
        {
            desc: "Time Of Order",
            value: formatRequestTime
        },
        {
            desc: "Merchant Name",
            value: merchantName
        },
        {
            desc: 'UserInfo',
            value: buyerNickName
        },
    ]
    const vaildPayDetailList = payDetailList.filter((item) => !!item.value)
    const handlePayMethodChange = (val: any) => {
        if(isDepositPay) {
            LogEvent({ eventName: 'SelectPayMethod_DepositePay' })
        }else {
            LogEvent({ eventName: 'SelectPayMethod_WalletBalance' })
        }
        // 余额不够时，支付方式切换
        if(isDisabled && val !== payMethodEum.walletPay) {
            setIsDisabled(false)
        }else {
            setIsDisabled((orderInfo?.orderAmount || 0) - Number(avaAmount) > 0)
        }
        setPayMethod(val as string)
    }
    const renderPayList = () => {
        return vaildPayDetailList.map((item) => {
            const { desc, value, copy } = item
            return (
                <div className="pay-item" key={desc + value}>
                    <div className="desc">{desc}</div>
                    <div
                        onClick={() => {
                            if(!copy) return
                            setCopyId(value || '')
                        }}
                        className={`value ${copy ? 'user-order-id-copy' : ''}`}
                    >
                        {value}{copy && <div className='copy-img'><img src={copyLogo} alt="copy" /></div>}
                    </div>
                </div>
            )
        })
    }
    const renderPayMethod = () => {
        return (
            <div className='pay-method'>
                <div className="title">Payment Methods</div>
                <Radio.Group
                    value={payMethod}
                    onChange={handlePayMethodChange}
                >
                    <Radio value={payMethodEum.walletPay} className="pay-item">
                        <div className="pay-title">VIBRA Wallet Crypto Balance</div>
                        <div className="pay-desc">Available Balance: {formatFixNumString(avaAmount) || 0} USDT</div>
                    </Radio>
                    <div className="balance-tips">{isDisabled ? 'Low balance of wallet' : ''}</div>
                    <Radio value={payMethodEum.depositPay} className="pay-item">
                        <div className="pay-title">Deposit to VIBRA and Pay</div>
                        <div className="pay-desc">Deposit Amount: {formatOrderAmount || 0}</div>
                    </Radio>
                </Radio.Group>
            </div>
        )
    }
    const handleOrderPay = () => {
        // 钱包余额不够禁用 || 接口报错
        if(disabled) return
        if(!agreeProtocol) {
            Toast.show('please agree and read VIBRA Pay Privacy Policy')
            return
        }
        onOrderPay?.(payMethod)
    }
    const handleVibraPayAgreement = () => {
        window.location.href = vibraPayAgreementUrl
    }
    useEffect(() => {
        setIsDisabled(orderAmount - Number(avaAmount) > 0)
    }, [avaAmount, orderAmount])

    useEffect(() => {
        const clipboard = new ClipboardJS('.user-order-id-copy', {
            text: function () {
                return copyId
            }
        })
        clipboard.on('success', () => {
            Toast.show('Copy Success')
        })
        clipboard.on('error', () => {
            Toast.show('Copy Failed')
        })
    }, [copyId])

    return (
        <div className="pay-card-container">
            { showPayMethod && renderPayMethod() }
            <div className="pay-mount-card">
                <div className="title">Order Summary</div>
                <div className="pay-list">
                    { renderPayList() }
                </div>
                <div className="total-container">
                    <div className="desc">Total</div>
                    <div className="total">{formatOrderAmount}</div>
                </div>
            </div>
            {
                showPayMethod && (
                    <>
                        <div className='agreement-wrapper'>
                            <Radio
                                checked={agreeProtocol}
                                onChange={(val) => {setAgreeProtocol(val)}}
                                className="agreement-radio"
                            >
                                I have read and agree to
                            </Radio>
                            <span className="agreement" onClick={handleVibraPayAgreement}>VIBRA Pay Privacy Policy.</span>
                        </div>
                        <Button className={`pay-btn ${disabled ? 'pay-btn-disable' : ''}`} onClick={handleOrderPay}>Pay Now</Button>
                    </>
                )
            }
        </div>
    )
}