import { useEffect, useRef, useState } from "react"
import SliderModal from 'react-modal'
import { LogEvent, ResToast } from '@/utils'
import { phoneApi } from "@/apis"
import MsgKey from "@/constants/MsgKey"
import { ISendSmsReq } from '@/types/customer'
import './index.scss'
type IVerifyCodeCardProps = {
    onInputSuccess: (verifyCode: string) => void
    isError?: boolean
    scene: string
    mobile?: string
    businessType?: number
    elementId: string
    resendEventName?: string
    OPTLoadSuccessEventName?: string
    OPTLoadFailEventName?: string
    OPTVerifiySuccessEventName?: string
    getCodeSuccessEventName?: string
}
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "340px",
      height: "100px",
      padding: 0,
      zIndex: 2000,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }
}

const defaultVerifyCodeLen = 6
const defaultStartSeconds = 60
let timeout: any
let secondsRef: number

let inputVerifyCode: string[] = []

export default function VerifyCodeInput(props: IVerifyCodeCardProps) {
    const { onInputSuccess, scene, mobile = '', businessType, elementId, isError, resendEventName, OPTLoadFailEventName, OPTLoadSuccessEventName, OPTVerifiySuccessEventName, getCodeSuccessEventName } = props
    const [modalIsOpen, setModalIsOpen] = useState(false)
    let modalIsOpenCount = 1
    // 保证弹层打开的唯一性

    const [seconds, setSeconds] = useState(0)
    const [verifyCode, setVerifyCode] = useState('')
    secondsRef = seconds
    // 获取sessionId等验证信息
    const sigRef = useRef('')
    const ncTokenRef = useRef('')
    const csessionidRef = useRef('')

    const inputElementRef = useRef<any>(null)
    const inputs = new Array(defaultVerifyCodeLen).fill('code-input')

    const ncRef = useRef<any>(null)

    const handleDeleteInputVal = (e: any) => {
        const inputElements: any[] = inputElementRef.current || []
        const curIndex = e.target.getAttribute("data-index")
        // 删除验证码 & 前一个input聚焦
        if(e && e.keyCode === 8) {
            const preIndex = Math.max(0, curIndex - 1)
            inputVerifyCode[curIndex] = ''
            inputElements[preIndex].focus()
            setVerifyCode(inputVerifyCode.join(''))
        }
    }
    const handleInputChange = (e: any) => {
        const inputElements: any[] = inputElementRef.current || []
        const curIndex = e.target.getAttribute("data-index")
        let numIndex = parseInt(curIndex)
        const [first, ...rest] = e.target.value
        //如果输入的不是数字
        if (!first || !/[0-9]/.test(first)) {
            e.target.value = ''
            return
        }
        inputVerifyCode[numIndex] = first
        // input value 赋值 & 移动光标 —— 值是粘贴过来的
        const len = Math.min(rest.length, inputElements.length - 1)
        for(let i=0; i<len; i++) {
            numIndex++
            inputElements[numIndex]?.focus()
            inputVerifyCode[numIndex] = rest[i]
        }
        if(numIndex < inputElements.length - 1 && !inputVerifyCode[numIndex + 1]) {
            inputElements[numIndex + 1].focus()
        }
        inputElements[numIndex]?.blur()
        // 快捷输入 or 过滤无效值
        const vaildVerifyCode = inputVerifyCode.filter(val => !!val)
        const verifyCodeStr = vaildVerifyCode.join('')
        // 验证码输入完成时，直接验证
        if(defaultVerifyCodeLen === vaildVerifyCode.length && seconds) {
            onInputSuccess(verifyCodeStr)
        }
        setVerifyCode(verifyCodeStr)
    }
    const handleResend = () => {
        if(seconds) return
        resendEventName &&  LogEvent({ eventName: resendEventName })
        openSliderModal()
    }
    const closeSliderModal = () => {
        modalIsOpenCount--
        setModalIsOpen(false)
    }
    // 倒计时
    const startTimer = () => {
        setSeconds(defaultStartSeconds)
        clearInterval(timeout)
        timeout = setInterval(() => {
            if (secondsRef === 0) {
                clearInterval(timeout)
                return
            }
            setSeconds(secondsRef - 1)
        }, 1000)
    }
    // 获取验证码
    const handleVerifyCode = async () => {
        const params: ISendSmsReq = {
            mobile,
            businessType
        }
        window?.Loading?.show()
        const res = await phoneApi.sendSms(params, {
            csessionid: csessionidRef?.current,
            sig: sigRef?.current,
            nc_token: ncTokenRef?.current,
            scene
        })
        window?.Loading?.hide()
        if (res?.data?.success && res?.data?.msgKey === MsgKey.CUSTOMER_VERIFY_CODE_SENT) {
            getCodeSuccessEventName && LogEvent({ eventName: getCodeSuccessEventName })
            // 倒计时
            startTimer()
        }else {
            ResToast(res?.data)
        }
    }
    const initNc = () => {
        // 实例化nc
        (window as any).AWSC?.use("nc", function (_state: any, module: any) {
          // 初始化
          ncRef.current = module.init({
            language: "en",
            // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
            appkey: "FFFF0N1N000000006429",
            //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在阿里云验证码控制台的配置管理页签找到对应的scene值，请务必正确填写。
            scene,
            // 声明滑动验证需要渲染的目标ID。
            renderTo: "nc",
            //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将会话ID（sessionId）、签名串（sig）、请求唯一标识（token）字段记录下来，随业务请求一同发送至您的服务端调用验签。
            success: function (data: any) {
                OPTVerifiySuccessEventName && LogEvent({ eventName: OPTVerifiySuccessEventName })
                const { sessionId, sig, token } = data || {}
                window.console && console.log(data)
                csessionidRef.current = sessionId
                sigRef.current = sig
                ncTokenRef.current = token
                closeSliderModal()
                handleVerifyCode()
            },
            // 滑动验证失败时触发该回调参数。
            fail: function (failCode: any) {
                OPTLoadFailEventName && LogEvent({
                    eventName: OPTLoadFailEventName,
                    params: { error: 'window.AWSC Verifiy fail function' }
                })
                window.console && console.log(failCode)
            },
            // 验证码加载出现异常时触发该回调参数。
            error: function (errorCode: any) {
                OPTLoadFailEventName && LogEvent({
                    eventName: OPTLoadFailEventName,
                    params: { error: 'window.AWSC load error function' }
                })
                window.console && console.log(errorCode)
            }
          })
        })
    }
    // 打开滑动验证弹层
    const openSliderModal = () => {
        if(!!(window as any)?.AWSC?.use) {
            modalIsOpenCount++
            setModalIsOpen(true)
        }else {
            OPTLoadSuccessEventName && LogEvent({
                eventName: OPTLoadSuccessEventName,
                params: { error: 'not window.AWSC & use' }
            })
        }
        setTimeout(() => {
            initNc()
        }, 200)
    }
    const loadAwscScript = () => {
        const body = document.getElementsByTagName('body')[0]
        const script = document.createElement('script')
        script.src = 'https://g.alicdn.com/AWSC/AWSC/awsc.js'
        script.onerror = (e) => {
            console.log(`error ${e}`)
        }
        script.onload = () => {
            modalIsOpenCount === 1 && openSliderModal()
        }
        body.appendChild(script)

        SliderModal.setAppElement(`#${elementId}`)
    }
    useEffect(() => {
        const inputs: any = document.querySelectorAll('input.code-input')
        inputElementRef.current = [...inputs]
        // 加载 awsc 脚本，初始化nc
        loadAwscScript()
        return () => {
            inputVerifyCode = []
            clearTimeout(timeout)
        }
    }, [])

    const errorTips = verifyCode.length === defaultVerifyCodeLen && (isError || !seconds)
    return (
        <div className="verify-code-input">
            <div className="input-container" onKeyUp={handleDeleteInputVal} onChange={handleInputChange}>
                {
                    inputs.map((input, index) => {
                        return <input value={inputVerifyCode[index]} key={index} style={{border: `2px solid ${errorTips ? '#D92A0F' : '#5D5F70'}`}} type="number" min={0} autoFocus={index===0} data-index={index} pattern="[0-9]*" className={input} required/>
                    })
                }
            </div>
            {errorTips && <div className="error-tips">The code you entered is incorrect</div>}
            <div className="desc">
                Didn't receive the verification code? <span className="resend" onClick={() => {handleResend()}}>{seconds ? `${seconds}s` : 'Resend'}</span>
            </div>
            <SliderModal
                isOpen={modalIsOpen}
                onRequestClose={closeSliderModal}
                style={customStyles}
                contentLabel="Example Modal"
                preventScroll={true}
            >
                <div id="nc"></div>
            </SliderModal>
        </div>
    )
}