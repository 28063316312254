import RouterConst from '@/constants/Router'
import Home from "@/pages/Home/index"
import LogIn from "@/pages/Login/index"
import VerifyCode from '@/pages/VerifyCode/index'
import PayDetail from '@/pages/PayDetail/index'
import DepositPayDetail from '@/pages/DepositPayDetail/index'
import PayResult from '@/pages/PayResult/index'
// 全局路由配置
const routes = [
    {
      path: '/',
      element: <Home />,
      meta: {
        title: '首页',
        needLogin: true,
      },
    },
    {
      path: RouterConst.Login,
      element: <LogIn />,
      meta: {
        title: 'vibra login',
        needLogin: true,
      },
    },
    {
      path: RouterConst.VerifyCode,
      element: <VerifyCode />,
      meta: {
        title: 'vibra verifyCode',
        needLogin: true,
      },
    },
    {
      path: RouterConst.PayDetail,
      element: <PayDetail />,
      meta: {
        title: 'vibra payDetail',
        needLogin: true,
      },
    },
    {
      path: RouterConst.DepositPayDetail,
      element: <DepositPayDetail />,
      meta: {
        title: 'vibra depositPayDetail',
        needLogin: true,
      },
    },
    {
      path: RouterConst.PayResult,
      element: <PayResult />,
      meta: {
        title: 'vibra payResult',
        needLogin: true,
      },
    }
]
  /**
   * @description: 全局路由拦截
   * @param {string} pathname 当前路由路径
   * @param {object} meta 当前路由自定义meta字段
   * @return {string} 需要跳转到其他页时，就返回一个该页的path路径，或返回resolve该路径的promise对象
   */
  // const onRouteBefore = ({ pathname, meta }) => {
  //   // 动态修改页面title
  //   if (meta.title !== undefined) {
  //     document.title = meta.title
  //   }
  //   // 判断未登录跳转登录页
  //   if (meta.needLogin) {
  //     if (!isLogin) {
  //       return '/login'
  //     }
  //   }
  // }
  export {
    routes,
    // onRouteBefore,
  }