import { axios } from "@/libs/request"
import { host } from "@/config"
import { ICurrencyAssets } from '@/types/pay'

const baseUrl = `${host}/account`

// 获取钱包指定币种资产
export const getCurrencyAssets = (params: {
  coinCode?: string
}): Promise<IBaseRes<ICurrencyAssets>> => {
  return axios.post(`${baseUrl}/getVirtualAccount`, params)
}