import ReactDOM from 'react-dom/client'
import { useRoutes } from 'react-router-dom'
import Loading from '@/components/Loading'
import { routes } from './routers'
import './App.scss'

function App () {
  const elements = useRoutes(routes)
  return elements
}

function preventDefault(e: any) {
  e.preventDefault()
}

// left: 37, up: 38, right: 39, down: 40
const keys: any = { 37: 1, 38: 1, 39: 1, 40: 1 }

let supportsPassive = false;
try {
  window.addEventListener("test", () => {}, Object.defineProperty({}, "passive", {
      get: function () {
        supportsPassive = true
      },
    })
  );
} catch (e) {}

function preventDefaultForScrollKeys(e: any) {
  if (keys[e.keyCode]) {
    preventDefault(e)
    return false
  }
}
const wheelOpt = supportsPassive ? { passive: false } : false
const wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel"

// call this to Disable
function disableScroll() {
  window.addEventListener("DOMMouseScroll", preventDefault, false) // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt) // modern desktop
  window.addEventListener("touchmove", preventDefault, wheelOpt) // mobile
  window.addEventListener("keydown", preventDefaultForScrollKeys, false)
}

// call this to Enable
function enableScroll() {
  window.removeEventListener("DOMMouseScroll", preventDefault, false)
  window.removeEventListener(wheelEvent, preventDefault)
  window.removeEventListener("touchmove", preventDefault)
  window.removeEventListener("keydown", preventDefaultForScrollKeys, false)
}

// 保证弹层 和 禁用的唯一性
let disableCount = 0

// 定义全局loading函数，用于发送请求loading弹层
window.Loading = {
  show: () => {
    disableScroll()
    disableCount++
    if(disableCount > 1) return
    const loading = document.createElement('div')
    const root = document.getElementById('root')
    loading.id = 'global-loading'

    root?.insertBefore(loading, root?.firstChild)

    const loadingRoot = ReactDOM.createRoot(
      document.getElementById('global-loading') as HTMLElement
    )
    loadingRoot.render(
      <Loading />
    )
  },
  hide: () => {
    disableCount--
    if(disableCount > 0) return
    enableScroll()
    const loading: any = document.getElementById('global-loading')
    const root = document.getElementById('root')
    root?.removeChild(loading)
  }
}

export default App