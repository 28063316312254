import { axios } from "@/libs/request"
import { host } from "@/config"
import { RegisterRequest, IVerifyReq, ILoginReq, ILoginRes, CustomerSimpleInfo } from "@/types/customer"

const baseUrl = `${host}/customer`

/**
 * 用户信息
 * @param params 
 * @returns 
 */
 export const userInfo = (): Promise<IBaseRes<CustomerSimpleInfo>> => {
  return axios.get(`${baseUrl}`)
}

/**
 * 是否注册
 * @param params
 * @returns
 */
export const isRegister = (params: {
  mobile?: string
  email?: string
}): Promise<IBaseRes> => {
  return axios.get(`${baseUrl}/querycustomer`, {
    params
  })
}


/**
 * 注册
 * @param params
 * @returns
 */

export const register = (params: RegisterRequest): Promise<IBaseRes<ILoginRes>> => {
  return axios.post(`${baseUrl}/register/quick`, params)
}

/**
 * 登录
 * @param params
 * @returns
 */
export const login = (params: ILoginReq): Promise<IBaseRes<ILoginRes>> => {
  return axios.post(`${baseUrl}/login/quick`, params)
}

/**
 * 验证码验证
 * @param params
 * @returns
 */
export const verify = (params: IVerifyReq): Promise<IBaseRes<string>> => {
  return axios.post(`${baseUrl}/verify`, params)
}

