import MsgKey from "@/constants/MsgKey"
import httpClient from "axios"
import qs from "qs"
import RouterConst from '@/constants/Router'
import { getToken, removeToken } from "@/utils/local"
import { Toast } from 'antd-mobile'
import { env } from "@/config"

const axios = httpClient.create()

let timerId: any
type IConfig = {
  cancelToken: any
  headers: {
    saas_id: string
    JWT_TOKEN: string
    traffic: string
    'Content-Type': string
  }
  method: string
  data: any
  url: string
  params: any
  validateStatus: (status: number) => boolean
}

axios.interceptors.request.use(
  async (config: IConfig | any) => {

    let source = httpClient.CancelToken.source();
    timerId = setTimeout(() => {
      source.cancel();
    }, 5000);
    config.cancelToken = source.token;

    config.headers['saas_id'] = "vibra"

    const token = getToken() || ''
    token && (config.headers['JWT_TOKEN'] = `${token}`)

    // 蓝绿发布
    if (env === "pre") {
      config.headers['traffic'] = `green`
    }

    // 在发送请求之前做什么
    if (config.method === "post") {
      if (!config.data?.json) {
        config.data = qs.stringify(config.data);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
      } else {
        config.headers['Content-Type'] = 'application/json'
      }
    }

    // language=en-US
    const url = config?.url
    if (url) {
      if (url.indexOf('language') === -1) {
        config.url += url.indexOf('?') >= 0 ? '&language=en-US' : '?language=en-US'
      }
    }

    console.log(`Url: ${config.url}`)
    // console.log(`JWT_TOKEN: ${config.headers['JWT_TOKEN']}`)
    config.params && console.log(`Params: ${JSON.stringify(config.params)}`)
    config.data && console.log(`Body: ${JSON.stringify(config.data)}`)

    config.validateStatus = function (status: number) {
      return status >= 200 && status < 300; // 默认的，当状态码200-300间时，axios返回resolve状态的promise否则返回rejecte状态的
    }

    // console.log(config.headers)

    return config;
  },
  error => {
    console.error(error, "error");
    Toast.clear()
    return Promise.resolve({
      data: {
        success: false
      }
    });
  }
)


axios.interceptors.response.use(
  // status >= 200 && status < 300
  async (response: any) => {
    clearTimeout(timerId)
    // console.log(`Url ${response.config.url}`)

    const blackList = ["market/symbol", "market/currencie", "crypto/configs", "account/assets"]
    if (blackList?.some((item: string) => response?.config?.url?.indexOf(item) >= 0)) {
    } else {
      // console.log(`Url ${response.config.url}, Data ${JSON.stringify(response.config.data)}, Params ${response.config.params && JSON.stringify(response.config.params)}, Response: ${JSON.stringify(response.data)}`)
    }

    if (response.status === 200) {
      if (response?.data?.msgKey === MsgKey.CUSTOMER_LOGIN_REQUIRED) {
        console.log('未登录重定向操作～～～～')
        removeToken()
        // h5页面接口未登录时，跳转登录页
        if(window.location.pathname.includes(RouterConst.Login)) return
        window.location.replace(`${window.location.origin}${RouterConst.Login}${window.location.search}`)
        return Promise.resolve(response);
      } else {

        if (response.data?.code === "9999") {
          Toast.show({
            content: response.data.msgKey,
            icon: 'fail'
          })
          return Promise.resolve({
            success: false,
            msg: "system error",
            status: 200
          });
        }

        return Promise.resolve(response);
      }
    } else {
      // 除200以外的2xx
      Toast.clear()
      return Promise.resolve({
        success: false,
        msg: "network error",
        status: response.status
      });
    }
  },

  // status < 200 && status >= 300
  error => {
    clearTimeout(timerId)
    Toast.clear()
    console.error(error);
    return Promise.resolve({
      data: {
        success: false,
        msg: "network error",
        status: 500
      }
    });
  }
);

export {
  axios
}