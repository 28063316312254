import { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { setToken } from "@/utils/local"
import { LogEvent, ResToast } from '@/utils'
import { customerApi } from "@/apis"
import RouterConst from '@/constants/Router'
import MsgKey from "@/constants/MsgKey"
import BuzzLogo from '@/components/BuzzLogo'
import Header from '@/components/Header'
import VerifyCodeInput from '@/components/VerifyCodeInput'
import PayCard from '@/components/PayCard'
import jwtDecode from 'jwt-decode'
import { ILoginReq } from '@/types/customer'
import { IDecodeOrderInfo } from '@/types/pay'
import { verifyInputSceneEum, businessTypeEum } from '@/config'
import './index.scss'

type IVerifyCodeParams = {
    phoneNumber: string
    token: string
}

const initOrderInfo = {
    orderAmount: 0,
    currency: '',
    orderDescription: '',
    merchantName: '',
    paymentId: '',
    customizations: {
        logo: '',
        title: ''
    },
    referenceOrderId: '',
    requestTime: '',
    buyerNickName: ''
}

export default function VerifyCode() {
    const { state } = useLocation()
    const navigate = useNavigate()
    const { phoneNumber = '', token } = state as IVerifyCodeParams || {} as IVerifyCodeParams
    let orderInfo = initOrderInfo

    try {
        const decodeOrderInfo = jwtDecode(token) as IDecodeOrderInfo
        orderInfo = {
            orderAmount: decodeOrderInfo.amount,
            currency: decodeOrderInfo.currency,
            orderDescription: decodeOrderInfo.orderDescription,
            merchantName: decodeOrderInfo.merchantName,
            paymentId: decodeOrderInfo.paymentId,
            customizations: {
                logo: decodeOrderInfo.logo,
                title: decodeOrderInfo.title
            },
            referenceOrderId: decodeOrderInfo.referenceOrderId,
            requestTime: decodeOrderInfo.requestTime,
            buyerNickName: decodeOrderInfo.buyerNickName
        }
    }catch {
        orderInfo = initOrderInfo
    }

    const [isError, setIsError] = useState(false)

    const { customizations, ...payCardInfo } = orderInfo

    // check验证码 —— 后端判断用户未注册 自动注册
    const handleCheckVerifyCode = async (verifyCode: string) => {
        const params: ILoginReq = {
            pTokenType: "web",
            token: "token",
            deviceId: "",
            mobile: phoneNumber,
            source: 0,
            smsVerifyCode: verifyCode,
            autoRegister: true
        }
        window?.Loading?.show()
        const res = await customerApi.login(params)
        window?.Loading?.hide()
        const { msgKey, obj } = res?.data || {}

        if (msgKey === MsgKey.CUSTOMER_LOGIN_SUCCESS) {
            LogEvent({ eventName: 'VpayLoginRegsite_SubmitCodeSuccess' })
            const { jwtKey } = obj || {}
            setToken(jwtKey)
            // 支付详情页面
            navigate(`${RouterConst.PayDetail}?token=${token}`, { replace: true })
        }else if(msgKey === MsgKey.CUSTOMER_PHONE_CODE_INCORRECT) {
            // 验证码错误
            setIsError(true)
        }else {
            ResToast(res?.data)
        }
    }
    return (
        <div id="vibra-verify-code">
            <Header />
            <div className="verify-code-container">
                <BuzzLogo logo={customizations?.logo} />
                <div className="verify-code-card">
                    <div className="title">Verify Your Identify</div>
                    <div className="sub-title">Enter the code sent to your phone to use your verification.</div>
                    <VerifyCodeInput
                        onInputSuccess={handleCheckVerifyCode}
                        scene={verifyInputSceneEum.login}
                        businessType={businessTypeEum.login}
                        elementId="vibra-verify-code"
                        isError={isError}
                        mobile={phoneNumber}
                        resendEventName="VpayLoginRegsite_ResendCode"
                        OPTLoadSuccessEventName="VpayLoginRegsite_OTP_LoadSuccess"
                        OPTLoadFailEventName="VpayLoginRegsite_OTP_LoadFail"
                        OPTVerifiySuccessEventName="VpayLoginRegsite_OTP_VerifiySuccess"
                        getCodeSuccessEventName="VpayLoginRegsite_GetCodeSuccess"
                    />
                </div>
                <PayCard orderInfo={payCardInfo} />
            </div>
        </div>
    )
}
