import { useEffect, useState, useRef, useCallback } from 'react'
import { useNavigate } from "react-router-dom"
import RouterConst from '@/constants/Router'
import { LogEvent, ResToast, formatFixNumString } from '@/utils'
import qs from 'query-string'
import _ from 'lodash'
import QRCode from 'qrcode'
import BuzzLogo from '@/components/BuzzLogo'
import { Dropdown, Radio, Space, Toast } from 'antd-mobile'
import { DownOutline } from 'antd-mobile-icons'
import Header from '@/components/Header'
import ClipboardJS from 'clipboard'
import Modal from '@/components/Modal'
import UserInfo from '@/components/UserInfo'
import bigDecimal from 'js-big-decimal'
import PayCard from '@/components/PayCard'
import CountDown from '@/components/CountDown'
import { payApi, accountApi } from '@/apis'
import { orderStatusEum } from '@/config/index'
import { ICurrencyListAssetsItem, IPaymentsDetailRes, ICurrencyListConfig } from '@/types/pay'
import './index.scss'

const initOrderInfo = {
    orderAmount: 0,
    currency: '',
    orderDescription: '',
    merchantName: '',
    paymentId: '',
    status: '',
    timeExpire: 0,
    customizations: {
        logo: '',
        title: ''
    },
    referenceOrderId: '',
    requestTime: '',
    buyerNickName: ''
}

const networkOptions = [
    {
        key: 'BEP20',
        value: 'USDT_BEP20'
    },
    {
        key: 'TRC20',
        value: 'USDT_TRC20'
    }
]

const defaultNetwork = {
    key: 'BEP20',
    value: 'USDT_BEP20'
}
let timer: any = null
// usdt network —— "BEP20" & "TRC20"

export default function DepositPayDetail() {
    const navigate = useNavigate()
    const depositPayDetailRef: any = useRef()

    let searchObj = {} as { token: string }
    try {
        searchObj = qs.parse(window.location.search) as { token: string }
    } catch {
        searchObj = {} as { token: string }
    }
    const { token } = searchObj

    const [qrUrl, setQrUrl] = useState('')
    const [assetNetwork, setAssetNetwork] = useState(defaultNetwork)
    const [assetDetail, setAssetDetail] = useState({} as ICurrencyListAssetsItem)
    const [qrCodeUrl, setQrCodeUrl] = useState('')
    const [orderInfo, setOrderInfo] = useState(initOrderInfo)

    const [currencyInfo, setCurrencyInfo] = useState<ICurrencyListConfig[]>([])
    const [avaAmount, setAvaAmount] = useState('')

    const [visible, setVisible] = useState(false)

    const { customizations, ...payCardInfo } = orderInfo

    const formatOrderAmount = `${formatFixNumString(orderInfo.orderAmount || 0)} ${orderInfo.currency || ''}`
    // 异步set orderInfo 在setTimeout & await 都取不到最新设置的值
    const orderCurrencyType = useRef<any>()
    orderCurrencyType.current = orderInfo.currency

    const fetchQrAddress = async (network: string) => {
        // 充币地址
        window?.Loading?.show()
        const qrCodeAddressRes = await payApi.getAddressNew(network)
        window?.Loading?.hide()
        const qrCodeAddress = qrCodeAddressRes?.data.obj || {}
        if(qrCodeAddressRes?.data?.success) {
            if(qrCodeAddress?.address) {
                setQrCodeUrl(qrCodeAddress?.address)
            }else {
                const res = await payApi.genAddressNew(network)
                res?.data?.success ? fetchQrAddress(network) : setQrCodeUrl('')
            }
        }else {
            setQrCodeUrl('')
            ResToast(qrCodeAddressRes?.data)
        }
    }

    const fetchCurrencyInfo = async () => {
        // 获取最小充币金额
        const currencyInfoRes = await payApi.getCoinConfigAllNew()
        if(!currencyInfoRes?.data?.success) {
            ResToast(currencyInfoRes?.data)
            return
        }
        const currencyInfo = currencyInfoRes?.data.obj || []
        setCurrencyInfo(currencyInfo)
        const currentCurrencyAssets = currencyInfo.find(a => a.currency === orderCurrencyType.current)?.assets?.filter((item) => item?.enableDeposit) || []
        const assetDetail = currentCurrencyAssets?.find((item) => item?.asset === assetNetwork.value) || {} as ICurrencyListAssetsItem
        setAssetDetail(assetDetail)
    }
    const refreshDepositInfo = useCallback(async () => {
        const paymentsDetailRes = await payApi.getPaymentsDetail(token) || {}
        if(!paymentsDetailRes?.data?.success) {
            ResToast(paymentsDetailRes?.data)
            return
        }
        const { orderAmount, status, timeExpire, customizations, paymentId, orderDescription, merchant, requestTime, referenceOrderId,  buyer } = paymentsDetailRes.data.obj || {} as IPaymentsDetailRes

        setOrderInfo({
            orderDescription,
            orderAmount: orderAmount?.value || 0,
            currency: orderAmount?.currency,
            status,
            timeExpire,
            customizations,
            paymentId,
            merchantName: merchant?.merchantName || '',
            referenceOrderId,
            requestTime,
            buyerNickName: buyer?.buyerNickName || ''
        })

        const accountInfoRes = await accountApi.getCurrencyAssets({ coinCode: orderAmount?.currency || '' })
        if(!accountInfoRes?.data?.success) {
            ResToast(accountInfoRes?.data)
            return
        }
        setAvaAmount(accountInfoRes.data.obj?.hotMoney)
    }, [token])

    const moveCloseDropdownModal = () => {
        depositPayDetailRef?.current?.click()
    }

    const dropDownFuncDef = () => {
        const _element = depositPayDetailRef.current
        if(!_element) return
        let _startPos = 0
        let _transitionHeight = 0

        _element.addEventListener('touchstart', (e: any) => {
            console.log('初始位置：', e.touches[0].pageY)
            _startPos = e.touches[0].pageY
            moveCloseDropdownModal()
        }, false);

        _element.addEventListener('touchmove', _.throttle((e: any) => {
            console.log('当前位置：', e.touches[0].pageY)
            const curPosition = e.touches[0].pageY
            _transitionHeight = curPosition - _startPos
        }, 100), false)

        _element.addEventListener('touchend', async (e: any) => {
            console.log('滑动距离：',_transitionHeight)
            if(_startPos < 600 && _transitionHeight > 40) {
                window?.Loading?.show()
                await refreshDepositInfo()
                window?.Loading?.hide()
            }
            _transitionHeight = 0
        }, false)
    }

    useEffect(() => {
        dropDownFuncDef()
        const fetchData = async () => {
            window?.Loading?.show()
            await refreshDepositInfo()

            await fetchCurrencyInfo()
            await fetchQrAddress(assetNetwork.value)
            window?.Loading?.hide()
        }
        timer = setInterval(() => {
            refreshDepositInfo()
        }, 1000 * 60)
        fetchData()
        return () => {
            timer && clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        if(qrCodeUrl) {
            const canvas = document.getElementById("qr-code-img")
            QRCode.toCanvas(canvas, qrCodeUrl, function (error: any) {
                if (error) console.error(error)
                console.log('success!')
            })
        }
        const clipboard = new ClipboardJS('.qr-code-copy', {
            text: function () {
                return qrUrl
            }
        })
        clipboard.on('success', () => {
            Toast.show('Copy Success')
        })
        clipboard.on('error', () => {
            Toast.show('Copy Failed')
        })
    }, [qrUrl, qrCodeUrl])

    const handleGoResult = () => {
        navigate(RouterConst.PayResult, { state: { token }, replace: true })
    }

    useEffect(() => {
        if(orderInfo.status === orderStatusEum.PAID || orderInfo.status === orderStatusEum.CANCELED) {
            handleGoResult()
        }
    }, [orderInfo.status])
    const minDepositAmount = `${formatFixNumString(assetDetail?.minDepositAmount || 0)} ${assetDetail?.currency || ''}`
    const needDepositAmount = Math.max(parseFloat(bigDecimal.subtract(orderInfo.orderAmount, avaAmount)), 0)
    const payDetailData = [
        {
            desc: 'Total Amount',
            value: formatOrderAmount
        },
        {
            desc: 'Payment Status',
            value: orderInfo.status
        },
        {
            desc: 'Amount of CRYPTO Received so far:',
            value: `${formatFixNumString(avaAmount)} USDT`
        },
        {
            desc: 'Remaining Amount of CRYPTO to be Chargerd:',
            value: `${formatFixNumString(needDepositAmount)} USDT`
        }
    ]
    const noticesData = [
        '1.Sending any other crypto or token to this address will result in the loss of your deposit except ETH.',
        '2.Only after 35 network confirmations crypto will be deposited,or equivalent amount of your assets will be temporarily unavailable for withdrawals.',
        `3.Minium deposit:  ${minDepositAmount} \n The minimum deposit is based on the amount after deducting the withdrawal fee from sending platform,which is the amount shown on the TXID;Any deposit lower than minimum deposit will not be recovered or refunded.`
    ]

    const handleCopyQrurl = () => {
        setQrUrl(qrCodeUrl)
    }

    const handleNetworkChange = async (val: any) => {
        const network = networkOptions.find((item) => item.value === val) || defaultNetwork
        setAssetNetwork(network)
        await fetchQrAddress(network.value)
        const currentCurrencyAssets = currencyInfo.find(a => a.currency === orderCurrencyType.current)?.assets?.filter((item) => item?.enableDeposit) || []
        const assetDetail = currentCurrencyAssets?.find((item) => item?.asset === network.value) || {} as ICurrencyListAssetsItem
        setAssetDetail(assetDetail)
        moveCloseDropdownModal()
    }
    const handleCancelOrder = async () => {
        LogEvent({ eventName: 'SelectPayMethod_DepositePay_CancelOrder' })
        window?.Loading?.show()
        const res = await payApi.cancelPayments({ paymentId: orderInfo.paymentId })
        window?.Loading?.hide()
        if (res?.data?.success) {
            handleGoResult()
        }else {
            ResToast(res?.data)
        }
    }
    const handleOpenCancelOrderModal = () => {
        setVisible(true)
    }

    const handleCloseCancelOrderModal = () => {
        setVisible(false)
    }

    const renderOrderDetailList = () => {
        return (
            <div className="pay-detail-list">
                {
                    payDetailData.map((payDetail, index) => {
                        const { desc, value } = payDetail
                        return (
                            <div key={index} className="pay-detail-item">
                                <div className="desc">{desc}</div>
                                <div className="value">{value}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const renderNetworkSelect = () => {
        return (
            <div className="network-select">
                <div className="title">Select Network</div>
                <Dropdown arrow={<DownOutline />} closeOnClickAway closeOnMaskClick>
                    <Dropdown.Item key='BEP20' title={assetNetwork.key} >
                        <div style={{ padding: 12 }}>
                        <Radio.Group defaultValue='USDT_BEP20' onChange={handleNetworkChange}>
                            <Space direction='vertical' block>
                            {
                                networkOptions.map((item) => {
                                    const { key, value } = item
                                    return (
                                        <Radio key={key} block value={value}>
                                        {key}
                                        </Radio>
                                    )
                                })
                            }
                            </Space>
                        </Radio.Group>
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            </div>
        )
    }

    const renderQrcode = () => {
        return (
            <div className="qr-code-container">
                <div className="qr-code">
                    {qrCodeUrl ? <canvas id="qr-code-img"></canvas> : <></>}
                    <div className="qr-code-desc">
                        <div className="network">
                            Send only USDT to this address.Ensure the network is <span className="value">{assetNetwork.key}</span>
                        </div>
                        <div className="desc-container">
                            <div className="desc">Minimum Deposit</div>
                            <div className="value">{minDepositAmount}</div>
                        </div>
                    </div>
                </div>
                <div className="qr-code-url">
                    <div id="qr-code-val">{qrCodeUrl}</div>
                    <div className="qr-code-copy" onClick={handleCopyQrurl} data-clipboard-action="copy" data-clipboard-target="#qr-code-val">copy</div>
                </div>
            </div>
        )
    }

    const renderNoticeTips = () => {
        return (
            <div className="notice-tips">
                <div className="title">Notice:</div>
                {
                    noticesData.map(tip => {
                        return (
                            <div key={tip} className="notice-item">{tip}</div>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className="deposit-pay-detail">
            <Header backEventName='DepositPay_ClickBack' />
            {
                orderInfo.timeExpire ? <CountDown timeStamp={orderInfo.timeExpire} /> : <></>
            }
            <div className="pay-detail-container" ref={depositPayDetailRef}>
                <BuzzLogo logo={customizations?.logo} />
                <UserInfo />
                <div className="order-detail">
                    {renderOrderDetailList()}
                    {renderNetworkSelect()}
                    {renderQrcode()}
                    {renderNoticeTips()}
                </div>
                <PayCard orderInfo={payCardInfo} />
                <div className="cancel-order" onClick={handleOpenCancelOrderModal}> Cancel Order </div>
                <Modal
                    title="Confirm Order Cancellation"
                    content="The order will be canceled after confirmation. Please confirm again!"
                    visible={visible}
                    onClose={handleCloseCancelOrderModal}
                    cancelText="Cancel"
                    onCancel={handleCloseCancelOrderModal}
                    confirmText="Confirm"
                    onConfirm={handleCancelOrder}
                    bodyClassName="cancel-order-confirm-modal"
                />
            </div>
        </div>
    )
}