import { Modal } from 'antd-mobile'
import './index.scss'

type IMyModalProps = {
    title?: string
    content: any
    visible: boolean
    cancelText?: string
    onCancel?: Function
    confirmText?: string
    onConfirm?: Function
    showCloseButton?: boolean
    onClose?: () => void
    bodyClassName?: string
}
const btnEum = {
    confirm: 'confirm',
    cancel: 'cancel'
}

export default function MyModal(props: IMyModalProps) {
    const { title, content, visible, cancelText, onCancel, confirmText, onConfirm, showCloseButton = true, onClose, bodyClassName } = props
    const actions = []
    if(cancelText) {
        actions.push({
            key: btnEum.cancel,
            text: cancelText
        })
    }
    if(confirmText) {
        actions.push( {
            key: btnEum.confirm,
            text: confirmText
        })
    }
    const onAction = (action: any) => {
        switch (action?.key) {
            case btnEum.confirm:
                onConfirm?.()
                break
            case btnEum.cancel:
                onCancel?.()
                break
            default:
                break
        }
    }
    const realContent = typeof content === 'string' ? content : content?.()
    return (
        <Modal
          visible={visible}
          content={realContent}
          onAction={onAction}
          showCloseButton={showCloseButton}
          title={title}
          actions={actions}
          onClose={onClose}
          bodyClassName={bodyClassName}
        />
    )
}