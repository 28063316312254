// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dev: {
    "HOST": "https://api.vibra.dipbit.xyz",
    "SOCIAL_HOST": "https://newapi.beta.vibra.work",
    "URL_CDN": "https://vibra-beta.oss-ap-southeast-1.aliyuncs.com",
    "SHARE_URL": "https://vibra-h5-git-dev-bhaltair.vercel.app"
  },
  beta: {
    "HOST": "https://api.beta.vibra.work",
    "SOCIAL_HOST": "https://newapi.beta.vibra.work",
    "URL_CDN": "https://vibra-beta.oss-ap-southeast-1.aliyuncs.com",
    "SHARE_URL": "https://vibra-h5-git-dev-bhaltair.vercel.app"
  },
  prod: {
    "HOST": "https://api.vibra.one",
    "SOCIAL_HOST": "https://newapi.vibra.one",
    "URL_CDN": "https://upload.vibra.one",
    "SHARE_URL": "https://m.vibra.one"
  }
}