import { useState, useEffect, useRef } from "react"
import moment from 'moment'
import './index.scss'

interface IProps {
    timeStamp: any
}

const CountDown = (props: IProps) => {
    const { timeStamp } = props
    const intervalRef = useRef<any>(null)
    const [time, setTime] = useState('') //时间间隔
    const vaildTime = time.replace(/:|0/g, '')
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            const start = moment(new Date()) //获取开始时间
            const end = moment(new Date(timeStamp)) //结束时间
            const diff = end.diff(start) //时间差
            const time = moment.utc(diff).format("HH:mm:ss")
            setTime(time)
        }, 1000)
        return () => clearInterval(intervalRef.current)
    }, [timeStamp])

    return (
        vaildTime ? (
            <div className="count-down">
                <div className="desc">Payment Time</div>
                <div  className="time">
                    { vaildTime ? <span>{time}</span> : ''}
                </div>
            </div>
        ) : <></>
    )

}

export default CountDown
