import { useEffect, useState, useRef, Ref } from 'react'
import { useNavigate } from "react-router-dom"
import { ICurrencyAssets, IPaymentsDetailRes } from '@/types/pay'
import qs from 'query-string'
import jwtDecode from 'jwt-decode'
import { IDecodeOrderInfo } from '@/types/pay'
import { LogEvent, ResToast } from '@/utils'
import MsgKey from "@/constants/MsgKey"
import { CustomerSimpleInfo } from "@/types/customer"
import { verifyInputSceneEum, orderStatusEum, businessTypeEum } from '@/config'
import RouterConst from '@/constants/Router'
import Header from '@/components/Header'
import BuzzLogo from '@/components/BuzzLogo'
import moment from 'moment'
import UserInfo from '@/components/UserInfo'
import PayCard from '@/components/PayCard'
import Modal from '@/components/Modal'
import VerifyCodeInput from '@/components/VerifyCodeInput'
import { customerApi, accountApi, payApi } from "@/apis"

import './index.scss'

let timer: any

const payMethodEum = {
    walletPay: 'BALANCE_PAYMENT',
    depositPay: 'ONE_TIME_DEBIT'
}
const submitEventEum: any = {
    BALANCE_PAYMENT: 'WalletBalance',
    ONE_TIME_DEBIT: 'DepositePay'
}
const initOrderInfo = {
    orderAmount: 0,
    currency: '',
    orderDescription: '',
    merchantName: '',
    paymentId: '',
    customizations: {
        logo: '',
        title: ''
    },
    payerMobile: '',
    status: '',
    referenceOrderId: '',
    requestTime: '',
    buyerNickName: ''
}

export default function PayDetail() {
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false)
    const [isError, setIsError] = useState(false)
    const [payMethod, setPayMethod] = useState('')
    const [avaAmount, setAvaAmount] = useState('')
    const [systemError, setSystemError] = useState(false)

    const [orderInfo, setOrderInfo] = useState(initOrderInfo)

    const { customizations, ...payCardInfo } = orderInfo

    const userInfoRef: Ref<any> = useRef()

    const timeExpireRef = useRef(false)

    const userInfo: CustomerSimpleInfo = userInfoRef.current?.getUserInfo() || {}

    let searchObj = {} as { token: string }
    try {
        searchObj = qs.parse(window.location.search) as { token: string }
    } catch {
        searchObj = {} as { token: string }
    }
    const { token = '' } = searchObj

    let decodeOrderInfo = {} as IDecodeOrderInfo
    try {
        decodeOrderInfo = jwtDecode(token) as IDecodeOrderInfo
    }catch {
        decodeOrderInfo = {} as IDecodeOrderInfo
    }
    const { time, redirectUrl } = decodeOrderInfo
    const currentTime = moment(new Date()).valueOf()
    // 订单12小时过期，不用登录直接跳转
    if(currentTime - time >= 0) {
        ResToast({ code: MsgKey.ORDER_IS_BOUND })
        timeExpireRef.current = true
        timer = setTimeout(() => {
            redirectUrl && window.location.replace(redirectUrl)
        }, 3000)
    }

    useEffect(() => {
        if(timeExpireRef.current) {
            return
        }
        const fetchPayDetailInfo = async () => {
            let accountInfoRes = {} as IBaseRes<ICurrencyAssets>
            let paymentsDetailRes = {} as IBaseRes<IPaymentsDetailRes>
            window?.Loading?.show()
            try {
                paymentsDetailRes = await payApi.getPaymentsDetail(token)
                const coinCode = paymentsDetailRes?.data?.obj?.orderAmount?.currency || ''
                accountInfoRes = (coinCode ? await accountApi.getCurrencyAssets({ coinCode }) : {} as IBaseRes<ICurrencyAssets>)
            }catch {
                accountInfoRes = {} as IBaseRes<ICurrencyAssets>
                paymentsDetailRes = {} as IBaseRes<IPaymentsDetailRes>
            }
            window?.Loading?.hide()
            const { orderAmount, orderDescription, merchant, paymentId, customizations, payerMobile, status, referenceOrderId, requestTime, buyer } = paymentsDetailRes?.data?.obj || {}
            if(paymentsDetailRes?.data?.success) {
                setOrderInfo({
                    orderAmount: orderAmount?.value || 0,
                    currency: orderAmount?.currency  || '',
                    orderDescription,
                    merchantName: merchant?.merchantName || '',
                    paymentId,
                    customizations,
                    payerMobile,
                    status,
                    referenceOrderId,
                    requestTime,
                    buyerNickName: buyer?.buyerNickName || ''
                })
            }else {
                ResToast(paymentsDetailRes?.data)
                if(paymentsDetailRes?.data?.code === MsgKey.ORDER_IS_BOUND) {
                    timer = setTimeout(() => {
                        redirectUrl && window.location.replace(redirectUrl)
                    }, 3000)
                }
                return
            }
            if(accountInfoRes?.data?.success) {
                setAvaAmount(accountInfoRes.data.obj?.hotMoney)
            }else {
                ResToast(accountInfoRes?.data)
            }
            if(!paymentsDetailRes?.data?.success || !accountInfoRes?.data?.success) {
                setSystemError(true)
            }else {
                setSystemError(false)
            }
        }
        fetchPayDetailInfo()
        LogEvent({ eventName: 'SelectPayMethod_Page', params: { desc: 'pay detail' } })

        return () => timer && clearTimeout(timer)
    }, [token])

    useEffect(() => {
        // 已有支付结果 - 跳转结果页
        if(orderInfo.status === orderStatusEum.PAID || orderInfo.status === orderStatusEum.CANCELED) {
            goPayResult()
        }else if(orderInfo.status === orderStatusEum.PROCESSING) {
            goDepositPayDetail()
        }
    }, [orderInfo.status])

    const goPayResult = () => {
        navigate(RouterConst.PayResult, { state: { token }, replace: true })
    }

    const goDepositPayDetail = () => {
        navigate(`${RouterConst.DepositPayDetail}?token=${token}`, { replace: true })
    }

    const handleOrderPay = (payMethod: string) => {
        setVisible(true)
        setPayMethod(payMethod)
    }
    const handleCheckVerifyCode = async (verifyCode: string) => {
        const params = {
            mobile: orderInfo.payerMobile,
            type: businessTypeEum.vibraPay,
            verifyCode,
        }
        window?.Loading?.show()
        const res = await customerApi.verify(params)

        const { msgKey, obj } = res?.data || {}

        if (res?.data?.success) {
            LogEvent({ eventName: 'SelectPayMethod_SubmitSuccess', params: { desc: submitEventEum[payMethod]} })
            const confirmRes =  await payApi.fetchPaymentsConfirm({
                paymentId: orderInfo.paymentId,
                paymentType: payMethod,
                token: obj,
                json: true
            })
            window?.Loading?.hide()
            if(confirmRes?.data?.success) {
                LogEvent({ eventName: 'SelectPayMethod_PaySuccess', params: { desc: submitEventEum[payMethod]} })
                if(payMethod === payMethodEum.depositPay) {
                    goDepositPayDetail()
                }else if(payMethod === payMethodEum.walletPay) {
                    goPayResult()
                }
            }else {
                LogEvent({ eventName: 'SelectPayMethod_PayFail', params: { desc: submitEventEum[payMethod]} })
                ResToast(confirmRes?.data)
            }
        }else if(msgKey === MsgKey.CUSTOMER_PHONE_CODE_INCORRECT) {
            // 验证码错误
            window?.Loading?.hide()
            setIsError(true)
        }else {
            ResToast(res?.data)
            window?.Loading?.hide()
        }
    }
    const renderVerifyCodeContent = () => {
        return (
            <div className="pay-check-modal">
                <div className="title">Verify and Pay</div>
                <div className="desc">Enter the 6-digital verification code sent to phone number {userInfo.phone}</div>
                {visible && <VerifyCodeInput
                    onInputSuccess={handleCheckVerifyCode}
                    mobile={orderInfo.payerMobile}
                    scene={verifyInputSceneEum.other}
                    businessType={businessTypeEum.vibraPay}
                    elementId="pay-detail"
                    isError={isError}
                    resendEventName="SelectPayMethod_ResendCode"
                    OPTLoadSuccessEventName="SelectPayMethod_OTP_LoadSuccess"
                    OPTLoadFailEventName="SelectPayMethod_OTP_LoadFail"
                    OPTVerifiySuccessEventName="SelectPayMethod_OTP_VerificationSuccess"
                    getCodeSuccessEventName="SelectPayMethod_GetCodeSuccess"
                />}
            </div>
        )
    }
    return (
        <div id="pay-detail">
            <Header backEventName='SelectPayMethod_ClickBack' />
            <div className="pay-detail-container">
                <BuzzLogo logo={customizations?.logo} />
                {!timeExpireRef.current && <UserInfo ref={userInfoRef} />}
                <PayCard
                    orderInfo={payCardInfo}
                    showPayMethod={true}
                    onOrderPay={handleOrderPay}
                    avaAmount={avaAmount}
                    systemError={systemError}
                />
            </div>
            <Modal
                content={renderVerifyCodeContent}
                visible={visible}
                onClose={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}