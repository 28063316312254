import { axios } from "@/libs/request"
import { host } from "@/config"
import { ICurrencyListConfig, IAddressNew, IPaymentsDetailRes } from '@/types/pay'

const baseUrl = `${host}/v1`

// 获取最小金额
export const getCoinConfigAllNew = (): Promise<IBaseRes<ICurrencyListConfig[]>> => {
    return axios.get(`${baseUrl}/pay/currencies`)
}
// 获取地址
export const getAddressNew = (asset: string): Promise<IBaseRes<IAddressNew>> => {
    return axios.get(`${baseUrl}/wallet/addresses/${asset}`)
}
// 生成地址
export const genAddressNew = (asset: string): Promise<IBaseRes> => {
    return axios.post(`${baseUrl}/wallet/addresses/${asset}`)
}

// 获取订单详情
export const getPaymentsDetail = (token: string): Promise<IBaseRes<IPaymentsDetailRes>> => {
    return axios.get(`${baseUrl}/payment/orders/${token}`)
}

// 确认支付订单
export const fetchPaymentsConfirm = (params: {
    paymentId: string
    paymentType: string
    token: string
    json?: boolean
}): Promise<IBaseRes<any>> => {
    const {token, ...bodyData} = params
    return axios.post(`${baseUrl}/payment/confirm?token=${token}`, bodyData)
}

export const cancelPayments = (params: {
    paymentId: string
}): Promise<IBaseRes<any>> => {
    return axios.post(`${baseUrl}/payment/cancel`, {...params, json: true})
}