import { axios } from "@/libs/request"
import { host, isDev } from "@/config"
import { ISendSmsReq } from "@/types/customer"

const baseUrl = isDev ? `/phone` : `${host}/phone`

/**
 * 发送验证码
 * @param params
 * @returns 0105 正常
 */
export const sendSms = (params: ISendSmsReq, opts?: {
  sig: string
  nc_token: string
  scene: string
  csessionid: string
}): Promise<IBaseRes> => {
  return axios.post(`${baseUrl}/auth/code`, params, {
    headers: opts ? {
        "saas_id": "vibra",
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'SG-Verification-Type': 1,
        'SG-Verification-Sig': opts.sig,
        'SG-Verification-Token': opts.nc_token,
        'SG-Verification-Scene': opts.scene,
        'SG-Verification-Session-Id': opts.csessionid,
    } : {}
  })
}