import { useState } from 'react'
import { LogEvent } from '@/utils'
import { useNavigate } from "react-router-dom"
import { Button, Toast } from 'antd-mobile'
import CountryPicker, { Country, CountryCode } from 'react-native-country-picker-modal'
import vibraPay from '@/assets/vibraPay.png'
import { isValidMobile } from "@/utils/verify"
import RouterConst from '@/constants/Router'

import './index.scss'

type ICountryPhoneCodeSelector = {
  token: string
  title?: string
  desc?: string
}

const defaultCountryInfo = {
  callingCode: '234',
  cca2: 'NG',
  countryCode: 'NGN',
  country: 'Nigeria'
}

export default function LoginCard(props: ICountryPhoneCodeSelector) {
  const navigate = useNavigate()
  const { title, desc, token } = props
  const [phoneNumber, setPhoneNumber] = useState('')
  const [callingCode, setCallingCode] = useState(defaultCountryInfo.callingCode)
  const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountryInfo.cca2 as any)

  const handleInputChange = (event: any) => {
    setPhoneNumber(event.target.value || '')
  }
  const handleCheckPhoneNumber = async () => {
    const formatPhoneNumber = `+${callingCode}${phoneNumber}`
    if (!isValidMobile(formatPhoneNumber)) {
      Toast.show('Please enter a valid phone number')
      return
    }
    LogEvent({ eventName: 'VpayLoginRegsite_ClickContinue' })
    const navigateParams = { phoneNumber: formatPhoneNumber, token }
    navigate(RouterConst.VerifyCode, { state: navigateParams })
  }
  const handleCountryChange = (country: Country) => {
    setCallingCode(country.callingCode[0])
    setCountryCode(country.cca2)
  }


  return (
    <div className='phone-number-selector-wrapper'>
      {title && <div className="title">{title}</div>}
      {desc && <div className="desc">{desc}</div>}
      <div className="phone-number-selector">
        <CountryPicker
          {...{
            countryCode,
            withFilter: true,
            withFlag: true,
            withAlphaFilter: true,
            withCallingCode: true,
            withEmoji: true,
            onSelect: handleCountryChange
          }}
          modalProps={{ ariaHideApp: false }}
        />
        <div className='calling-code'>+{callingCode}</div>
        <input pattern="[0-9]*" onChange={handleInputChange} type="number" className="number-input" placeholder="Phone Number" />
      </div>
      <Button className="login-btn" color='primary' onClick={handleCheckPhoneNumber}>
        Continue with
        <img alt="" src={vibraPay} />
      </Button>
    </div>
  )
}