import qs from 'query-string'
import { useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import { IDecodeOrderInfo } from '@/types/pay'
import { LogEvent } from '@/utils'
import Header from '@/components/Header'
import BuzzLogo from '@/components/BuzzLogo'
import LoginCard from '@/components/LoginCard'
import PayCard from '@/components/PayCard'
import './index.scss'

const initOrderInfo = {
    orderAmount: 0,
    currency: '',
    orderDescription: '',
    merchantName: '',
    paymentId: '',
    customizations: {
        logo: '',
        title: ''
    },
    referenceOrderId: '',
    requestTime: '',
    buyerNickName: ''
}

export default function Login() {
    let orderInfo = initOrderInfo
    let searchObj = {} as { token: string }
    try {
        searchObj = qs.parse(window.location.search) as { token: string }
    } catch {
        searchObj = {} as { token: string }
    }
    const { token } = searchObj
    try {
        const decodeOrderInfo = jwtDecode(token) as IDecodeOrderInfo
        orderInfo = {
            orderAmount: decodeOrderInfo.amount,
            currency: decodeOrderInfo.currency,
            orderDescription: decodeOrderInfo.orderDescription,
            merchantName: decodeOrderInfo.merchantName,
            paymentId: decodeOrderInfo.paymentId,
            customizations: {
                logo: decodeOrderInfo.logo,
                title: decodeOrderInfo.title
            },
            referenceOrderId: decodeOrderInfo.referenceOrderId,
            requestTime: decodeOrderInfo.requestTime,
            buyerNickName: decodeOrderInfo.buyerNickName
        }
    }catch {
        orderInfo = initOrderInfo
    }

    useEffect(() => {
        LogEvent({
            eventName: 'VpayLoginRegsite_Page',
            params: {
                desc: 'user login & signup'
            }
        })
    }, [])
    const { customizations, ...payCardInfo } = orderInfo
    return (
        <div className="login">
            <Header backEventName="VpayLoginRegsite_CickBack" />
            <div className="login-container">
                <BuzzLogo logo={customizations?.logo} />
                <LoginCard
                    title="Login or Sign Up"
                    desc="Enter your phone number to login or sign up"
                    token={token || ''}
                />
                <PayCard
                    orderInfo={payCardInfo}
                />
            </div>
        </div>
    )
}