import { getCustomerId } from '@/utils/local'
import bigDecimal from 'js-big-decimal'
import MsgKey from "@/constants/MsgKey"
import { env } from '@/config'
import CodeRes from '@/constants/CodeRes'
import { Toast } from 'antd-mobile'

type ILogoEventProps = {
    eventType?: 'config' | 'event',
    eventName: string
    params?: any
}
const customerId = getCustomerId()

const gtagParams = {
    env
} as { env: string, customerId?: string }

if(customerId) {
    gtagParams.customerId = `id_${customerId}`
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages?hl=zh-cn
export const LogEvent = ({eventType = 'event', eventName, params = {}}: ILogoEventProps) => {
    (window as any).gtag(eventType, eventName, {...params, ...gtagParams })
}

export const ResToast = (res: {code?: string, msgKey?: string}) => {
    const { code = '', msgKey = '' } = res || {}
    if(code === MsgKey.CUSTOMER_LOGIN_REQUIRED || msgKey === MsgKey.CUSTOMER_LOGIN_REQUIRED) {
        return
    }
    Toast.show(CodeRes[code] || CodeRes[msgKey] || CodeRes['system.error'])
}

export const isNumberStr = (value: string) => {
    return /^[0-9]+\.{0,1}[0-9]{0,}$/.test(value)
}

export const formatFixNumString = function (numOrString: | string | number, digital: number = 8, noPretty?: boolean): string {
    const numberValue: number = Number(numOrString);
    let numberStr = isNaN(numberValue) ? '0.00' : numberValue;
    numberStr = bigDecimal.round(numberValue, digital, bigDecimal.RoundingModes.DOWN);
    if (Number(numberStr) === 0 || Number(numberStr) < 0.0001) {
      numberStr = Number(numOrString).toFixed(digital)
    }
    if (noPretty) return numberStr
    return getPrettyValue(numberStr)
}

export const getPrettyValue = (numberStr: string) => {
    if (isNumberStr(numberStr)) {
        return bigDecimal.getPrettyValue(numberStr, 3, ',');
    }
    return numberStr
}