import { Button } from 'antd-mobile'
import { LeftOutline } from 'antd-mobile-icons'
import { useNavigate } from "react-router-dom"
import { LogEvent } from '@/utils'
import './index.scss'
import defaultHeaderLogo from '@/assets/defaultHeaderLogo.png';

type IHeaderProps = {
    isBack?: boolean
    onClose?: () => void
    backEventName?: string
}

export default function Header(props: IHeaderProps) {
    const { isBack = true, onClose, backEventName } = props
    const navigate = useNavigate()
    const handleGoBack = () => {
        backEventName && LogEvent({ eventName: backEventName })
        if(onClose) {
            onClose()
            return
        }
        navigate(-1)
    }
    const handleDownLoadVibra = () => {
        LogEvent({ eventName: 'VIBRAPay_CickGetAPP' })
        window.location.href = 'https://vibra.onelink.me/ra0l/a1ccebe2?af_force_deeplink=true'
    }
    return (
        <div className="vibra-header">
            <header>
                {isBack && <div className='back' onClick={handleGoBack}><LeftOutline className='back-icon' /> Back</div>}
                <div className='title'>
                    <img src={defaultHeaderLogo} alt=""/>
                </div>
                <div className='right'>
                    <Button className="download" color='primary' onClick={handleDownLoadVibra}>Get the App</Button>
                </div>
            </header>
        </div>
    )
}